import * as React from 'react';
import { Theme } from '../common/enums';

export interface IComponent {
  id?: string;
  className?: string;
  children?: React.ReactNode | string | number;
  theme?: Theme;
  tooltip?: string;
  focused?: boolean;
  disabled?: boolean;
  active?: boolean;
  render?: boolean;
}
