import { Trend } from '../common/enums';

export type colors =
  | 'blue'
  | 'blue-very-light'
  | 'blue-light'
  | 'blue-bright'
  | 'purple'
  | 'purple-bright'
  | 'green'
  | 'green-very-light'
  | 'green-super-light'
  | 'green-faded'
  | 'green-extra-faded'
  | 'green-super-faded'
  | 'green-bright'
  | 'white'
  | 'black'
  | 'dark-grey'
  | 'transparent'
  | 'pink'
  | 'pink-light'
  | 'pink-very-light'
  | 'pink-bright'
  | 'yellow'
  | 'yellow-light'
  | 'yellow-faded'
  | 'yellow-bright'
  | 'red'
  | 'red-light'
  | 'red-faded'
  | 'red-bright'
  | 'industry'
  | 'oil-and-gas'
  | 'transport'
  | 'agriculture'
  | 'heating'
  | 'other';

export type colorTypes = 'rgb' | 'rgba' | 'hex';

export const getColorCode = (
  color: colors,
  type: colorTypes = 'rgb',
  opacity = 1
) => {
  if (typeof window === 'undefined') {
    return '';
  }

  switch (type) {
    case 'rgba':
      return `rgba(${getComputedStyle(
        document.documentElement
      ).getPropertyValue(`--${color}`)}, ${opacity})`;
    case 'rgb':
      return `rgb(${getComputedStyle(document.documentElement).getPropertyValue(
        `--${color}`
      )})`;
    case 'hex':
      return `${getComputedStyle(document.documentElement).getPropertyValue(
        `--hex-${color}`
      )}`;
    default:
      return `rgb(${getComputedStyle(document.documentElement).getPropertyValue(
        `--${color}`
      )})`;
  }
};

export const getCategoryColor = (color: string | colors) =>
  `rgb(${getComputedStyle(document.documentElement).getPropertyValue(
    `--${color}`
  )})`;

export const colorFromTrend = (trend: Trend) => {
  switch (trend) {
    case Trend.High:
      return 'red';
    case Trend.Flat:
      return 'yellow';
    case Trend.Low:
      return 'green';
    default:
      return 'black';
  }
};
