export type fontSize =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'label'
  | 'p'
  | 'text-tiny'
  | 'text-small'
  | 'text-medium'
  | 'text-default'
  | 'text-large'
  | 'text-huge';

export const getFontFamily = () => 'var(--font-libre-franklin)';

export const getFontSize = (size: fontSize) => {
  if (typeof window === 'undefined') {
    return '';
  }

  return `${getComputedStyle(document.documentElement).getPropertyValue(
    `--${size}`
  )}`;
};
