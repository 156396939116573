// ************************************
// Trend
// ************************************

// eslint-disable-next-line no-shadow -- enum
export enum Trend {
  Low,
  Flat,
  High,
  None,
}

export const trendToString = (t: Trend) => Object.entries(Trend)[t][1];

export const rotationFromTrend = (t: Trend, straight = false) => {
  switch (t) {
    case Trend.High:
      return straight ? 0 : -45;
    case Trend.Low:
      return straight ? 180 : 45;
    case Trend.Flat:
    default:
      return straight ? 90 : 0;
  }
};

// eslint-disable-next-line no-shadow -- enum
export enum EmissionCategory {
  All,
  Other,
  Transport,
  OilAndGas,
  Heating,
  Agriculture,
  Industry,
}

export const emissionCategoryToString = (category: EmissionCategory) =>
  Object.entries(EmissionCategory)[category][1];

// eslint-disable-next-line no-shadow -- enum
export enum Theme {
  None,
  Light,
  Dark,
  Locked,
  White,
  Red,
  Yellow,
  Green,
  Blue,
  LightGreen,
  Warning,
  Error,
  Grey,
}

export const themeToString = (theme: Theme) => Object.entries(Theme)[theme][1];

export const themeFromTrend = (trend: Trend) => {
  switch (trend) {
    case Trend.High:
      return Theme.Red;
    case Trend.Flat:
      return Theme.Yellow;
    case Trend.Low:
      return Theme.Green;
    default:
      return Theme.Dark;
  }
};
